import React, { useState, useEffect } from "react";
import { Box, Button, Image, Flex, Text, Spinner } from "@chakra-ui/react";
import axios from "axios";
import Chat from "./Chat";
import CharacterSelect from "./CharacterSelect";
import "./ChatApp.css";
import qs from "qs"; // Import qs library to help with query string encoding
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import DiscordImage from "./images/icons/discord.svg";

function ChatApp() {
  const [character, setCharacter] = useState(null);
  const [loginClicked, setLoginClicked] = useState(false);
//////////////// COMMENT IF LOCAL HOST /////////////////
 const [user, setUser] = useState(null);
  //const [user, setUser] = useState({username: "local"});

  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  const [loading, setLoading] = useState(true); // Add loading state

  const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=https%3A%2F%2Fkennycentral.com%2Fchat&scope=identify+email";
  //const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fchat&scope=identify+email";
  //const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fchat&scope=identify+email";

  const navigateToHome = () => {
    navigate("/"); // Navigate function called here
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code && !user) {
      handleLogin(code);
      // Clear URL after grabbing the code to avoid reuse
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, []);

//////////////// COMMENT IF LOCAL HOST /////////////////

useEffect(() => {
  // On initial load, try to fetch the user profile if the session is still active
  fetchUserProfile();
}, []);


axios.defaults.withCredentials = true;

const fetchUserProfile = async () => {
  setLoading(true); // Set loading to true when starting the fetch

  try {
    const response = await axios.get("https://api.kennycentral.com/api/user");
    setUser(response.data);
    setLoading(false); // Set loading to false after the fetch completes

  } catch (error) {
    console.error("Session not active:", error);
    setUser(null);
    setLoading(false); // Set loading to false after the fetch completes

  }
};

  const handleLogin = async () => {
    setLoading(true); // Set loading to false after the fetch completes

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      try {
        // Request to exchange code for tokens
        const response = await axios.post(
          "https://api.kennycentral.com/api/exchange",
          { code }
        );
        const { accessToken, refreshToken, expiresIn } = response.data;

        // Optionally set a timeout to refresh the token before it expires
        setTimeout(() => {
          refreshAccessToken(refreshToken);
        }, (expiresIn - 300) * 1000); // Refresh 5 minutes before expiration

        // Fetch user profile using accessToken
        const profile = await axios.get("https://discord.com/api/users/@me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setUser(profile.data);
        setLoading(false); // Set loading to false after the fetch completes

      } catch (error) {
        console.error("Login Failed:", error.response?.data);
        setLoading(false); // Set loading to false after the fetch completes

      }
    }
  };

  const refreshAccessToken = async (refreshToken) => {
    try {
      // Request to refresh the access token using the refresh token
      const response = await axios.post(
        "https://api.kennycentral.com/api/exchange",
        { refreshToken }
      );
      const { accessToken, expiresIn } = response.data;

      // Reset the timer for the next refresh
      setTimeout(() => {
        refreshAccessToken(refreshToken);
      }, (expiresIn - 300) * 1000); // Refresh 5 minutes before expiration
    } catch (error) {
      console.error("Failed to refresh access token:", error.response?.data);
    }
  };

  const handleSignInClick = () => {
    window.location.href = discordLoginUrl;
  };

  return (
    <Box>
{loading ? (
        <Flex justifyContent="center" alignItems="center" minHeight="300px">
          <Spinner size="xl" color="white" />
        </Flex>
      ) : user ? (        <>
          {character ? (
            <Chat
              character={character}
              setLoginClicked={setLoginClicked}
              user={user}
              setUser={setUser}
              onBack={() => setCharacter(null)}
            />
          ) : (
            <CharacterSelect
              onSelect={setCharacter}
              setLoginClicked={setLoginClicked}
              user={user}
              setUser={setUser}
            />
          )}
        </>
      ) : (
        <>
          <NavBar
            user={user}
            setLoginClicked={setLoginClicked}
            onBack={navigateToHome}
            isHome={true}
            title="Please sign in 🔒"
          />
          <Box textAlign="center" mt={10}>
            <Button
              className="button button-discord"
              leftIcon={<Image src={DiscordImage} boxSize="24px" />}
              colorScheme="purple"
              shadow="lg"
              onClick={handleSignInClick}
            >
              Login with Discord
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default ChatApp;
