import React from "react";
import { Box, Heading, Text } from "@chakra-ui/react";

const OutputDisplay = ({ dialogue, characterOptions }) => (
  <Box mt={4}>
    <Heading size="sm" textAlign="center" color="white" mb={2} mt={0}>Final Script</Heading>
    <Box bg="gray.100" p={3} rounded="md">
      {dialogue.length > 0 ? (
        dialogue.map((d, index) => {
          const char = characterOptions.find((char) => char.value === d.character);
          return (
            <Text key={index}>
              {char ? `${char.label}: ${d.line}` : `Unknown character: ${d.line}`}
            </Text>
          );
        })
      ) : (
        <Text>No dialogue added yet.</Text>
      )}
    </Box>
  </Box>
);

export default OutputDisplay;
