import React, { useState, useRef, useEffect } from "react";
import {
  Box, Stack, HStack, Select, Input, Button, FormLabel,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Text, Alert, AlertIcon
} from "@chakra-ui/react";
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, sortableKeyboardCoordinates, arrayMove } from '@dnd-kit/sortable';
import { KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import DialogueLine from "./DialogueLine";

const focusInputOnIOS = (inputRef) => {
  // Create a temporary hidden input element
  const tempInput = document.createElement('input');
  tempInput.style.position = 'absolute';
  tempInput.style.opacity = '0';
  tempInput.style.height = '0';
  tempInput.style.fontSize = '16px'; // Disable iOS auto-zoom
  document.body.appendChild(tempInput);

  // Focus on the hidden input to trigger keyboard
  tempInput.focus();

  // After a small delay, focus the actual input
  setTimeout(() => {
    inputRef.current.focus();  // Focus the desired input element
    inputRef.current.click();  // Trigger a click to ensure the keyboard opens
    document.body.removeChild(tempInput);  // Remove the temporary input
  }, 200); // Experiment with timing if needed
};


const DialogueInput = ({
  dialogue,
  setDialogue,
  characterOptions,
  selectedCharacters,
  currentCharacter,
  setCurrentCharacter,
  currentLine,
  setCurrentLine,
  updateDialogueCharacter,
  updateDialogueLine
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [maxLinesReached, setMaxLinesReached] = useState(false); // State for max lines reached alert
  const [characterLimitReached, setCharacterLimitReached] = useState(false); // State for character limit reached alert
  const textInputRef = useRef(null); // Reference for the text input

  const maxCharacters = 200; // Set the character limit

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    const preventScroll = (e) => {
      if (e.targetTouches.length === 1) {
        e.preventDefault();
      }
    };

    if (isDragging) {
      document.body.addEventListener('touchmove', preventScroll, { passive: false });
    } else {
      document.body.removeEventListener('touchmove', preventScroll);
    }

    return () => {
      document.body.removeEventListener('touchmove', preventScroll);
    };
  }, [isDragging]);


  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = (event) => {
    setIsDragging(false);
    const { active, over } = event;

    if (active.id !== over.id) {
      setDialogue((dialogue) => {
        const oldIndex = dialogue.findIndex((item) => item.id === active.id);
        const newIndex = dialogue.findIndex((item) => item.id === over.id);

        return arrayMove(dialogue, oldIndex, newIndex);
      });
    }
  };

  // Function to trigger delete confirmation modal
  const handleDeleteClick = (index) => {
    setDeleteIndex(index);
    setIsDeleteModalOpen(true);
  };

  // Function to delete the dialogue line after confirmation
  const handleDeleteConfirmed = () => {
    setDialogue((prevDialogue) => prevDialogue.filter((_, i) => i !== deleteIndex));
    setIsDeleteModalOpen(false);
    setMaxLinesReached(false); // Reset max lines alert if a line is deleted
  };


  const handleCharacterChange = (e) => {
    setCurrentCharacter(e.target.value);
   
  };
  

  // Handle input change and enforce character limit
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxCharacters) {
      setCurrentLine(inputValue);
      setCharacterLimitReached(false); // Hide alert if characters are within the limit
    } else {
      setCharacterLimitReached(true); // Show alert if character limit is exceeded
    }
  };

  // Handle paste event, truncate to 200 characters if the pasted content exceeds the limit
  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData('text');
    if (pasteData.length > maxCharacters) {
      e.preventDefault(); // Prevent the default paste behavior
      setCurrentLine(pasteData.slice(0, maxCharacters)); // Truncate and set the first 200 characters
      setCharacterLimitReached(true); // Show the alert
    }
  };

  // Function to add a dialogue line, limited to 30 lines
  const addDialogueLine = () => {
    if (dialogue.length >= 30) {
      setMaxLinesReached(true); // Show alert if the max number of lines is reached
      return;
    }
    if (currentCharacter && currentLine && currentLine.length <= maxCharacters) {
      setDialogue((prev) => [
        ...prev,
        { id: `dialogue-${prev.length}`, character: currentCharacter, line: currentLine },
      ]);
      setCurrentCharacter("");
      setCurrentLine("");
      setMaxLinesReached(false); // Hide alert when a line is successfully added
    }
  };

  return (
    <Box border="1px" borderColor="gray.300" p={4} rounded="md" bg="gray.50">
      <FormLabel>Enter Dialogue (Drag to Rearrange)</FormLabel>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={dialogue.map((d) => d.id)}>
          <Stack spacing={0}>
            {dialogue.map((d, index) => (
              <DialogueLine
                key={d.id}
                id={d.id}
                index={index}
                d={d}
                characterOptions={characterOptions}
                selectedCharacters={selectedCharacters}
                updateDialogueCharacter={updateDialogueCharacter}
                updateDialogueLine={updateDialogueLine}
                onDelete={() => handleDeleteClick(index)}
              />
            ))}
          </Stack>
        </SortableContext>
      </DndContext>

      <HStack mt={3}>
        <Select
          placeholder="Select a character"
          value={currentCharacter}
          onChange={handleCharacterChange}
          width="30%"
        >
          {characterOptions
            .filter((char) => selectedCharacters[char.value])
            .map((char) => (
              <option key={char.value} value={char.value}>
                {char.label}
              </option>
            ))}
        </Select>
        <Input
          ref={textInputRef}
          placeholder="Enter dialogue line"
          value={currentLine}
          onChange={handleInputChange}
          onPaste={handlePaste} // Handle paste event to truncate input
          width="70%"
          maxLength={maxCharacters} // Prevent typing more than 200 characters
        />
      </HStack>

      {characterLimitReached && (
        <Alert status="warning" mt={2}>
          <AlertIcon />
          Dialogue lines cannot exceed {maxCharacters} characters.
        </Alert>
      )}

      <Button
        onClick={addDialogueLine}
        mt={2}
        w="100%"
        variant="solid"
        isDisabled={!currentCharacter || !currentLine} // Disable button if no character or line is inputted
      >
        Add Line
      </Button>

      {maxLinesReached && (
        <Alert status="warning" mt={2}>
          <AlertIcon />
          Maximum of 30 dialogue lines reached. Please delete a line to add more.
        </Alert>
      )}

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        <ModalOverlay />
        <ModalContent w="90%">
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalBody>
            <Text>Are you sure you want to delete this row?</Text>
            <Text>{dialogue[deleteIndex]?.character}: {dialogue[deleteIndex]?.line}</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="red" onClick={handleDeleteConfirmed}>Delete</Button>
            <Button onClick={() => setIsDeleteModalOpen(false)} ml={3}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DialogueInput;
