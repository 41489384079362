import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './Home';
import ChatApp from './ChatApp';
import Creator from './Creator';
import CreatorMenu from './CreatorMenu';
import Create from './Create';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Flex,
  Stack,
  Avatar,
  IconButton,
  Icon,
  useColorMode,
  useMediaQuery,
  ChakraProvider
} from '@chakra-ui/react'
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
   <ChakraProvider>
   <Router>
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/chat" element={<ChatApp />} />
        <Route path="/create" element={<Creator />} />
    </Routes>
    </Router>
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
