import React from "react";
import { Box, HStack, Select, Input, IconButton } from "@chakra-ui/react";
import { FaGripLines, FaTrash } from "react-icons/fa";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const DialogueLine = ({
  d,
  index,
  updateDialogueCharacter,
  updateDialogueLine,
  characterOptions,
  selectedCharacters,
  onDelete
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: d.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      p={1}
      border="1px"
      borderColor="gray.200"
      rounded="md"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={2}
      bg="#ededed"
    >
      <HStack width="100%">
        <Select
          value={d.character}
          onChange={(e) => updateDialogueCharacter(index, e.target.value)}
          width="45%"
        >
          {characterOptions
            .filter((char) => selectedCharacters[char.value])
            .map((char) => (
              <option key={char.value} value={char.value}>
                {char.label}
              </option>
            ))}
        </Select>
        <Input
          value={d.line}
          onChange={(e) => updateDialogueLine(index, e.target.value)}
          width="45%"
        />
        <IconButton
          width="10%"
          aria-label="Delete"
          icon={<FaTrash />}
          variant="ghost"
          onClick={onDelete} // Trigger delete confirmation
        />
        <IconButton
          width="10%"
          aria-label="Drag"
          icon={<FaGripLines />}
          variant="ghost"
          {...listeners} // Attach drag listeners only to the drag icon
          {...attributes} // Attach drag attributes only to the drag icon
        />
      </HStack>
    </Box>
  );
};

export default DialogueLine;
