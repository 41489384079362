import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  Spinner,
  VStack,
  Alert,
  AlertIcon,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import { FaTrash } from "react-icons/fa"; // Import the delete icon
import axios from "axios";
import Create from "./Create";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";

function CreatorMenu({ user, setUser, setLoginClicked }) {
  const [loading, setLoading] = useState(false);
  const [savedSubmissions, setSavedSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [error, setError] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [submissionToDelete, setSubmissionToDelete] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchSavedSubmissions();
    }
  }, [user]);

  useEffect(() => {
    if (selectedSubmission === null && user) {
      fetchSavedSubmissions();
    }
  }, [selectedSubmission, user]);

  const fetchSavedSubmissions = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.kennycentral.com/api/savedSubmissions/${user.id}`,
        { withCredentials: true }
      );
      setSavedSubmissions(response.data);
      setLoading(false);
    } catch (err) {
      //console.error("Failed to fetch saved submissions:", err);
      setError("Error loading saved submissions.");
      setLoading(false);
    }
  };

  const handleNewSubmission = () => {
    setSelectedSubmission({});
  };

  const handleContinueSubmission = (submission) => {
    setSelectedSubmission(submission);
  };

  const handleDeleteClick = (submission) => {
    setSubmissionToDelete(submission);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (submissionToDelete) {
      try {
        await axios.delete(
          `https://api.kennycentral.com/api/savedSubmissions/${submissionToDelete.id}`,
          { withCredentials: true }
        );
        setSavedSubmissions((prev) =>
          prev.filter((s) => s.id !== submissionToDelete.id)
        );
        setIsDeleteModalOpen(false);
        setSubmissionToDelete(null);
      } catch (error) {
        //console.error("Failed to delete submission:", error);
        setError("Error deleting submission.");
      }
    }
  };

  if (loading) {
    return (
      <Flex justifyContent="center" alignItems="center" minHeight="300px">
        <Spinner size="xl" color="white" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Alert status="error" mt={4}>
        <AlertIcon />
        {error}
      </Alert>
    );
  }

  return (
    <Box>
      {selectedSubmission ? (
        <Create
          user={user}
          setUser={setUser}
          savedSubmission={selectedSubmission}
          onBack={() => setSelectedSubmission(null)}
        />
      ) : (
        <>
          <NavBar
            user={user}
            setLoginClicked={setLoginClicked}
            onBack={() => navigate("/")}
            isHome={true}
            title={user ? "Custom Episode Creator" : "Please sign in 🔒"}
            setUser={setUser}
          />
          <VStack spacing={4} mt={10}>
            <Button colorScheme="blue" onClick={handleNewSubmission} w={"80%"}>
              Create New Episode
            </Button>
            <Text fontWeight="bold" color="white">
              Continue from a Saved Episode
            </Text>
            {savedSubmissions.length > 0 ? (
              savedSubmissions.map((submission) => (
                <Flex key={submission.id} w="80%" alignItems="center">
                <Button
                  colorScheme="gray"
                  flex="1"
                  onClick={() => handleContinueSubmission(submission)}
                  display="flex"
                  justifyContent="center" // Center text horizontally
                  alignItems="center" // Center text vertically
                  position="relative" // Relative position for absolute icon placement
                >
                  <Box flex="1" textAlign="center"> {/* Centered Text */}
                    {submission.episode_name || "Untitled Submission"}
                  </Box>
                  <IconButton
                    aria-label="Delete submission"
                    icon={<FaTrash />}
                    w={"10%"}
                    variant="ghost"
                    position="absolute"
                    right="8px" // Position icon to the far right
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents button click from triggering parent onClick
                      handleDeleteClick(submission);
                    }}
                  />
                </Button>
              </Flex>
              
              
              ))
            ) : (
              <Text color="white">No saved episodes found.</Text>
            )}
          </VStack>
          <Modal
            isOpen={isDeleteModalOpen}
            onClose={() => setIsDeleteModalOpen(false)}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Confirm Deletion</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to delete this saved submission?
              </ModalBody>
              <ModalFooter>
                <Button
                  colorScheme="red"
                  mr={3}
                  onClick={handleDeleteConfirm}
                >
                  Delete
                </Button>
                <Button onClick={() => setIsDeleteModalOpen(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </Box>
  );
}

export default CreatorMenu;
