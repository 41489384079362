import React from "react";
import { Box, SimpleGrid, Button, Image, Tooltip } from "@chakra-ui/react";

const CharacterToggle = ({ characterOptions, selectedCharacters, toggleCharacter }) => (
  <Box mb={5}>
    <SimpleGrid minChildWidth="75px" spacing={2}>
      {characterOptions.map((char) => (
        <Box key={char.value} position="relative" w="75px" h="75px">
          <Tooltip label={char.label} fontSize="md">
            <Button
              onClick={() => toggleCharacter(char)}
              padding={0}
              w="100%"
              height="100%"
              border={selectedCharacters[char.value] ? "2px solid #39FF14" : "none"} // Neon green border when selected
              bg={selectedCharacters[char.value] ? "white" : "#D3D3D3"} // White when selected, grey when not selected
            >
              <Box position="relative" w="100%" h="100%">
                <Image
                  src={char.image}
                  alt={char.label}
                  objectFit="cover"
                  objectPosition="50% 10%"
                  width="100%"
                  height="100%"
                />

                {/* Dark overlay for unselected characters */}
                {!selectedCharacters[char.value] && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    bg="rgba(0, 0, 0, 0.4)" // Dark transparent overlay
                    zIndex={1}
                    _hover={{ bg: "none" }} // Remove overlay on hover
                    transition="background 0.2s ease" // Smooth transition
                  />
                )}
              </Box>
            </Button>
          </Tooltip>
        </Box>
      ))}
    </SimpleGrid>
  </Box>
);

export default CharacterToggle;
