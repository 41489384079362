import React, { useState, useEffect } from "react";
import { Box, Button, Image, Flex, Text, Spinner } from "@chakra-ui/react";
import axios from "axios";
import Create from "./Create";
import CreatorMenu from "./CreatorMenu";
import "./ChatApp.css";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import DiscordImage from "./images/icons/discord.svg";

function Creator() {
  const [loginClicked, setLoginClicked] = useState(false);
  const [user, setUser] = useState(null);
  const [hasAccess, setHasAccess] = useState(null); // Store access status
  const requiredRoles = [
    "1299015805228421241", "1196487817619050586", "1196487928440958976", "1196487730092318860",
];
const [loading, setLoading] = useState(true); // Add loading state

  const navigate = useNavigate(); // Using useNavigate instead of useHistory
  //const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=https%3A%2F%2Fkennycentral.com%2Fchat&scope=identify+email";
  //const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fchat&scope=identify+email";
  //const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fchat&scope=identify+email";
  //const discordLoginUrlDev = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fcreate&scope=guilds+guilds.members.read+identify+email";
  const discordLoginUrl = "https://discord.com/oauth2/authorize?client_id=1231304730408652851&response_type=code&redirect_uri=https%3A%2F%2Fkennycentral.com%2Fcreate&scope=guilds+guilds.members.read+identify+email";

  const navigateToHome = () => {
    navigate("/"); // Navigate function called here
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code && !user) {
      handleLogin(code);
      // Clear URL after grabbing the code to avoid reuse
      window.history.replaceState(null, null, window.location.pathname);
    }
  }, []);

  
  useEffect(() => {
    const fetchUserProfile = async () => {
      setLoading(true); // Set loading to true when starting the fetch

      try {
        const response = await axios.get(
          "https://api.kennycentral.com/api/user/roles",
          {
            withCredentials: true, // Include cookies in the request
          }
        );

        const { user, roles } = response.data;
        setUser(user);
        setHasAccess(roles.some((role) => requiredRoles.includes(role)));
        setLoading(false); // Set loading to false after the fetch completes

      } catch (error) {
        //console.error("Failed to fetch user or roles:", error);
        setHasAccess(false); // No access if fetch fails
        setLoading(false); // Set loading to false after the fetch completes

      }
    };

    fetchUserProfile();
  }, []);
  
  axios.defaults.withCredentials = true;

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get("https://api.kennycentral.com/api/user");
      setUser(response.data);
    } catch (error) {
      //console.error("Session not active:", error);
      setUser(null);
    }
  };

  const handleLogin = async () => {
    setLoading(true); // Set loading to false after the fetch completes

    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    if (code) {
      try {
        const response = await axios.post(
          "https://api.kennycentral.com/api/exchangecreate",
          { code }
        );
        const { accessToken, refreshToken, expiresIn, roles } = response.data;

        setHasAccess(roles.some((role) => requiredRoles.includes(role))); // Check for the required role

        const profile = await axios.get("https://discord.com/api/users/@me", {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setUser(profile.data);
        setLoading(false); // Set loading to false after the fetch completes

      } catch (error) {
        //console.error("Login Failed:", error.response?.data);
        setLoading(false); // Set loading to false after the fetch completes

      }
    }
  };

  const refreshAccessToken = async (refreshToken) => {
    try {
      // Request to refresh the access token using the refresh token
      const response = await axios.post(
        "https://api.kennycentral.com/api/exchangecreate",
        { refreshToken }
      );
      const { accessToken, expiresIn } = response.data;

      // Reset the timer for the next refresh
      setTimeout(() => {
        refreshAccessToken(refreshToken);
      }, (expiresIn - 300) * 1000); // Refresh 5 minutes before expiration
    } catch (error) {
      //console.error("Failed to refresh access token:", error.response?.data);
    }
  };

  const handleSignInClick = () => {
    //window.location.href = discordLoginUrlDev;
    window.location.href = discordLoginUrl;
  };

  const renderContent = () => {
    if (loading) {
      return (
        
        <Flex justifyContent="center" alignItems="center" minHeight="300px">
          <Spinner size="xl" color="white" />
        </Flex>
      );
    } else if (hasAccess === false) {
      return (
        <>
        <NavBar
        user={user}
        setLoginClicked={setLoginClicked}
        onBack={() => navigate("/")}
        isHome={true}
        title={user ? "Custom Episode Creator" : "Please sign in 🔒"}
        setUser={setUser}
      />
        <Flex justifyContent="center" alignItems="center" minHeight="300px">
          <Box
            bg="white"
            p={8}
            borderRadius="lg"
            boxShadow="lg"
            textAlign="center"
            maxWidth="400px"
          >
            <Text fontSize="lg" fontWeight="bold" mb={4}>
              Sorry, you must have at least Tier 2 Patreon (Lvl 10 Wizard) to
              access the custom episode creator.
              <br />
              <a
                href="https://www.patreon.com/kennycentral"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "purple", textDecoration: "underline" }}
              >
                Join Patreon here
              </a>
            </Text>
            <Button colorScheme="purple" onClick={navigateToHome}>
              Return to Home
            </Button>
          </Box>
        </Flex>
        </>
      );
    } else {
      return (
        <CreatorMenu
          setLoginClicked={setLoginClicked}
          user={user}
          setUser={setUser}
          onBack={() => navigate("/")}
        />
      );
    }
  };

  return (
    <Box>
      
      {user || loading ? (
        renderContent()
      ) : (
        <>
        <NavBar
        user={user}
        setLoginClicked={setLoginClicked}
        onBack={() => navigate("/")}
        isHome={true}
        title={user ? "Custom Episode Creator" : "Please sign in 🔒"}
        setUser={setUser}
      />
        <Box textAlign="center" mt={10}>
          <Button
            className="button button-discord"
            colorScheme="purple"
            shadow="lg"
            onClick={handleSignInClick}
          >
            Login with Discord
          </Button>
        </Box>
        </>
      )}
    </Box>
  );
}

export default Creator;