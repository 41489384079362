import React, { useState, useEffect } from "react";
import {
  Box,
  Stack,
  Flex,
  Heading,
  Select,
  Input,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import CharacterToggle from "./CreatorStuff/CharacterToggle";
import DialogueInput from "./CreatorStuff/DialogueInput";
import OutputDisplay from "./CreatorStuff/OutputDisplay";
import locations from "./data/locations.json";
import characterOptions from "./data/characters";
import axios from "axios";
import { differenceInMinutes, differenceInSeconds } from "date-fns";
import NavBar from "./NavBar";

function Create({ setLoginClicked, user, setUser, onBack, savedSubmission }) {
  const [type, setType] = useState(savedSubmission?.type || ""); // Load saved type if provided
  const [location, setLocation] = useState(savedSubmission?.location || "");
  const [episodeName, setEpisodeName] = useState(savedSubmission?.episode_name || "");
  const [selectedCharacters, setSelectedCharacters] = useState({});
  const [dialogue, setDialogue] = useState([]);
  const [currentCharacter, setCurrentCharacter] = useState("");
  const [currentLine, setCurrentLine] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [cooldownMessage, setCooldownMessage] = useState("");
  const [cooldownTime, setCooldownTime] = useState(null);
  const [remainingSubmissions, setRemainingSubmissions] = useState(null);
  const [resetTime, setResetTime] = useState(null);
  const [rolesData, setRolesData] = useState([]);
  const [resetCountdown, setResetCountdown] = useState("");
  const [noSubmissionsModalOpen, setNoSubmissionsModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false); // Save confirmation modal

  // Role-based limits for submissions per 24-hour period
  const roleLimits = {
    "1299015805228421241": Infinity, // Unlimited
    "1303123938968342578": Infinity, // Unlimited
    "1196487817619050586": 6,
    "1196487928440958976": 3,
    "1196487730092318860": 1,
  };
  const requiredRoles = [
    "1299015805228421241",
    "1196487817619050586",
    "1196487928440958976",
    "1196487730092318860",
  ];

  
  useEffect(() => {
    setNextResetCountdown();
    fetchUserData();
  }, [user.username]);
  

  const fetchUserData = async () => {
    try {
      // Fetch roles
      const response = await axios.get(
        "https://api.kennycentral.com/api/user/roles",
        { withCredentials: true }
      );
      setRolesData(response.data.roles);
    } catch (error) {
      //console.error("Error fetching user data:", error);
    }
  };

  // Run fetchRemainingSubmissions when rolesData changes
  useEffect(() => {
    if (rolesData.length > 0) {
      // Only call if rolesData has been set
      fetchRemainingSubmissions();
    }
  }, [rolesData]);

  const fetchRemainingSubmissions = async () => {
    try {
      const submissionData = await axios.get(
        `https://api.kennycentral.com/api/submissionCount/${user.username}`
      );
      const submissionCount = parseInt(
        submissionData.data.submission_count,
        10
      );


      // Check if the user has the unlimited role (1303123938968342578)
      const hasUnlimitedRole = rolesData.includes("1303123938968342578");

      // Determine the user's submission limit
      let userLimit = 0;

      if (hasUnlimitedRole) {
        userLimit = Infinity;
      } else {
        // Find the user's role that has a submission limit
        const userRole = rolesData.find((role) =>
          roleLimits.hasOwnProperty(role)
        );
        userLimit = roleLimits[userRole] ?? 0;
      }


      // Calculate remaining submissions
      const remaining =
        userLimit === Infinity
          ? Infinity
          : Math.max(userLimit - submissionCount, 0);
      if (remaining === 0) {
        setNoSubmissionsModalOpen(true); // Open modal if no submissions are left
      }
      setRemainingSubmissions(remaining);
    } catch (error) {
      //console.error("Error fetching submission count:", error);
    }
  };

  useEffect(() => {
    if (cooldownTime) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const totalSecondsRemaining = differenceInSeconds(cooldownTime, now);

        if (totalSecondsRemaining > 0) {
          const minutes = Math.floor(totalSecondsRemaining / 60);
          const seconds = totalSecondsRemaining % 60;
          setCooldownMessage(
            `Please wait ${minutes}m ${seconds}s before submitting again.`
          );
        } else {
          setCooldownMessage("");
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [cooldownTime]);

  // Calculate the next reset countdown
  const setNextResetCountdown = () => {
    const updateCountdown = () => {
      const now = new Date();
      const nextReset = new Date();
      nextReset.setUTCHours(15, 0, 0, 0); // Set to 15:00 UTC (9:00 AM CT)

      // If the current time is past today's reset time, set it for tomorrow at 15:00 UTC
      if (now >= nextReset) {
        nextReset.setUTCDate(nextReset.getUTCDate() + 1);
      }

      const diff = nextReset - now;
      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setResetCountdown(`Resets in ${hours}h ${minutes}m ${seconds}s`);
    };

    updateCountdown(); // Set initial countdown
    const countdownInterval = setInterval(updateCountdown, 1000);

    return () => clearInterval(countdownInterval);
  };

  useEffect(() => {
    if (savedSubmission && savedSubmission.dialogue) {  // Check if dialogue exists
      const savedDialogue = savedSubmission.dialogue.split("\n").map((line, index) => {
        const [character, lineText] = line.split(": ");
        return { id: `dialogue-${index}`, character, line: lineText };
      });
      setDialogue(savedDialogue);

      // Pre-select characters based on saved dialogue
      const characters = {};
      savedDialogue.forEach((d) => {
        const character = characterOptions.find((char) => char.value === d.character);
        if (character) characters[character.value] = true;
      });
      setSelectedCharacters(characters);
    }
  }, [savedSubmission]);

  const handleSaveForLater = async () => {
    const formattedDialogue = dialogue.map((d) => {
      const character = characterOptions.find(
        (char) => char.value === d.character
      );
      return `${character.value}: ${d.line}`;
    });
  
    const episodeData = {
      id: savedSubmission?.id || null, // Include the `id` if it's an existing saved submission
      episodeName,
      type,
      location,
      dialogue: formattedDialogue.join("\n"),
      discordId: user.id, // Use Discord ID
    };
  
    try {
      await axios.post(
        "https://api.kennycentral.com/api/saveEpisode",
        episodeData,
        {
          withCredentials: true,
        }
      );
      setIsSaveModalOpen(true); // Show save confirmation modal
    } catch (error) {
      //console.error("Error saving episode:", error);
    }
  };
  

  useEffect(() => {
    if (cooldownTime) {
      const intervalId = setInterval(() => {
        const now = new Date();
        const totalSecondsRemaining = differenceInSeconds(cooldownTime, now);

        if (totalSecondsRemaining > 0) {
          const minutes = Math.floor(totalSecondsRemaining / 60);
          const seconds = totalSecondsRemaining % 60;
          setCooldownMessage(
            `Please wait ${minutes}m ${seconds}s before submitting again.`
          );
        } else {
          setCooldownMessage("");
          clearInterval(intervalId);
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [cooldownTime]);

  // Add a new dialogue line
  const addDialogueLine = () => {
    if (currentCharacter && currentLine) {
      setDialogue((prev) => [
        ...prev,
        {
          id: `dialogue-${prev.length}`,
          character: currentCharacter,
          line: currentLine,
        },
      ]);
      setCurrentCharacter("");
      setCurrentLine("");
    }
  };

  const updateDialogueLine = (index, newLine) => {
    const updatedDialogue = [...dialogue];
    updatedDialogue[index].line = newLine;
    setDialogue(updatedDialogue);
  };

  // Validate required fields
  const validateFields = () => {
    const errors = {};
    if (!type) errors.type = "Please select an episode type.";
    if (!episodeName) errors.episodeName = "Please provide an episode name.";
    if (type === "Normal" && !location)
      errors.location = "Please provide a location.";
    if (dialogue.length === 0)
      errors.dialogue = "Please add at least one dialogue line.";
    return errors;
  };

  const handleSubmit = async () => {
    const errors = validateFields();
    setValidationErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    
    // Fetch user roles
    const { data: roleData } = await axios.get(
      "https://api.kennycentral.com/api/user/roles",
      { withCredentials: true }
    );
    const hasRequiredRole = roleData.roles.some((role) =>
      requiredRoles.includes(role)
    );

    if (!hasRequiredRole) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        role: "Sorry, you must have at least Tier 2 Patreon (Lvl 10 Wizard) to submit.",
      }));
      return;
    }

    // Fetch submission count within last 24 hours
    const { data: submissionData } = await axios.get(
      `https://api.kennycentral.com/api/submissionCount/${user.username}`
    );
    const submissionCount = parseInt(submissionData.submission_count, 10); // Ensure count is an integer

    // Define role-based submission limits
    const roleLimits = {
      "1299015805228421241": Infinity, // Unlimited for this role
      "1303123938968342578": Infinity, // Unlimited
      "1196487817619050586": 6, // Max 6 submissions per 24 hr
      "1196487928440958976": 3, // Max 3 submissions per 24 hr
      "1196487730092318860": 1, // Max 1 submission per 24 hr
    };

    // Define role-based submission limits
    const hasUnlimitedRole = roleData.roles.includes("1303123938968342578");
    const userRole = hasUnlimitedRole
      ? "1303123938968342578"
      : roleData.roles.find((role) => roleLimits.hasOwnProperty(role));
    const userLimit = roleLimits[userRole] ?? 0;

    if (submissionCount >= userLimit) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        submissionLimit: `Submission limit reached. You can submit ${userLimit} episode(s) per 24 hours.`,
      }));
      return;
    }

    // Check cooldown after verifying the submission limit
    const isCooldownPassed = await checkCooldown();
    if (!isCooldownPassed) {
      return;
    }

    // Adjust the episode name based on type
    const finalEpisodeName =
      type === "City Hall" ? `city hall ${episodeName}` : episodeName;

    const formattedDialogue = dialogue.map((d) => {
      const character = characterOptions.find(
        (char) => char.value === d.character
      );
      return `${character.value}: ${d.line}`;
    });

    // Prepare episode data, only include location if type is "Normal"
    const episodeData = {
      episodeName: finalEpisodeName,
      dialogue: formattedDialogue.join("\n"),
      user: user.username,
      ...(type === "Normal" && { setting: location }), // Conditionally include location
    };

    try {
      setIsSubmitting(true);
      await axios.post(
        "https://api.kennycentral.com/api/submitEpisode",
        episodeData
      );
      setIsModalOpen(true);
      if (savedSubmission?.id) {
        await axios.delete(`https://api.kennycentral.com/api/savedSubmissions/${savedSubmission.id}`);
      }
      setDialogue([]);
      setType("");
      setLocation("");
      setEpisodeName("");
      setValidationErrors({});
      await fetchRemainingSubmissions();
    } catch (error) {
      //console.error("Failed to submit episode:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkCooldown = async () => {
    try {
      const response = await axios.get(
        `https://api.kennycentral.com/api/lastSubmission/${user.username}`
      );
      const { submission_time } = response.data;

      if (submission_time) {
        const now = new Date();
        const lastSubmissionTime = new Date(submission_time);
        const minutesPassed = differenceInMinutes(now, lastSubmissionTime);
        const cooldownMinutes = 10;

        if (minutesPassed < cooldownMinutes) {
          const nextAvailableTime = new Date(lastSubmissionTime);
          nextAvailableTime.setMinutes(
            nextAvailableTime.getMinutes() + cooldownMinutes
          );
          setCooldownTime(nextAvailableTime);
          return false;
        }
      }

      return true;
    } catch (error) {
      //console.error("Error checking cooldown:", error);
      return false;
    }
  };

  const displayRemainingSubmissions = () => {
    if (remainingSubmissions === Infinity) {
      return "Unlimited submissions available within this period";
    }

    return `You currently have ${remainingSubmissions} submissions left in this 24-hour period (${resetCountdown})`;
  };

  return (
    <>
    <NavBar
    user={user}
    setLoginClicked={setLoginClicked}
    onBack={onBack}
    isHome={false}
    title={user ? "Custom Episode Creator" : "Please sign in 🔒"}
    setUser={setUser}
  />
    <Flex direction="column">
      <Flex flexGrow={1} direction="column">
        <Box p={4}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel color="white" fontWeight={"bold"}>
                Episode Type:
              </FormLabel>
              <Select
                value={type}
                onChange={(e) => setType(e.target.value)}
                color="white"
              >
                <option value="" disabled hidden>
                  Select Type
                </option>
                <option value="City Hall">City Hall</option>
                <option value="Normal">Normal</option>
              </Select>
            </FormControl>

            {type === "Normal" && (
              <FormControl>
                <FormLabel color="white" fontWeight={"bold"}>
                  Location:
                </FormLabel>
                <Select
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  color="white"
                >
                  <option value="" disabled hidden>
                    Select location
                  </option>
                  {locations.map((loc) => (
                    <option
                      key={loc.value}
                      value={loc.value}
                      style={{ backgroundColor: "black" }}
                    >
                      {loc.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            )}

            <FormControl>
              <FormLabel color="white" fontWeight={"bold"}>
                Episode Name:
              </FormLabel>
              <Input
                color="white"
                placeholder="Enter episode name"
                value={episodeName}
                onChange={(e) => setEpisodeName(e.target.value)}
              />
            </FormControl>

            <Heading size="sm" color="white" textAlign="center" mb={0} mt={5}>
              Choose Your Characters
            </Heading>

            <CharacterToggle
              characterOptions={characterOptions}
              selectedCharacters={selectedCharacters}
              toggleCharacter={(char) => {
                setSelectedCharacters((prev) => ({
                  ...prev,
                  [char.value]: !prev[char.value],
                }));
              }}
            />

            <DialogueInput
              dialogue={dialogue}
              setDialogue={setDialogue}
              characterOptions={characterOptions}
              selectedCharacters={selectedCharacters}
              currentCharacter={currentCharacter}
              setCurrentCharacter={setCurrentCharacter}
              currentLine={currentLine}
              setCurrentLine={setCurrentLine}
              addDialogueLine={addDialogueLine}
              updateDialogueLine={updateDialogueLine}
            />

            <OutputDisplay
              dialogue={dialogue}
              characterOptions={characterOptions}
            />

            {Object.keys(validationErrors).length > 0 && (
              <Box mt={4}>
                {validationErrors.type && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {validationErrors.type}
                  </Alert>
                )}
                {validationErrors.episodeName && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {validationErrors.episodeName}
                  </Alert>
                )}
                {validationErrors.location && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {validationErrors.location}
                  </Alert>
                )}
                {validationErrors.dialogue && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {validationErrors.dialogue}
                  </Alert>
                )}
                {validationErrors.role && (
                  <Alert status="error" mt={2}>
                    <AlertIcon />
                    {validationErrors.role}
                  </Alert>
                )}
              </Box>
            )}

            {cooldownMessage && (
              <Alert status="warning" mt={2}>
                <AlertIcon />
                {cooldownMessage}
              </Alert>
            )}
            <Text mt={8} color="white" fontWeight="bold" mb={2}>
              {displayRemainingSubmissions()}
            </Text>
            <Flex gap={4} mb={5}>
              {" "}
              <Button
                colorScheme="blue"
                w="50%"
                onClick={handleSubmit}
                isLoading={isSubmitting}
                isDisabled={isSubmitting || !!cooldownMessage}
              >
                Submit Episode
              </Button>
              <Button
                colorScheme="gray"
                w="50%"
                onClick={handleSaveForLater}
                isLoading={isSubmitting}
              >
                Save for Later
              </Button>
            </Flex>
          </Stack>
        </Box>
      </Flex>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Submission Successful</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Your episode has been submitted successfully!</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              w="100%"
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={noSubmissionsModalOpen} onClose={onBack}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>
            No Submissions Remaining
          </ModalHeader>
          <ModalBody>
            <Text>
              You have reached your submission limit for the day. Please try
              again after the reset.
            </Text>
            <Text mt={2}>{resetCountdown}</Text>{" "}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onBack} w="100%">
              Return to Home
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isSaveModalOpen} onClose={() => setIsSaveModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign={"center"}>Episode Saved</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Your episode has been saved for later!</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" w="100%" onClick={() => setIsSaveModalOpen(false)}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
    </>
  );
}

export default Create;
